@import "../utilities/mixins"

$box-color: $text !default
$box-background-color: $scheme-main !default
$box-radius: $radius-large !default
$box-shadow: $shadow !default
$box-padding: 1.25rem !default

$box-link-hover-shadow: 0 0.5em 1em -0.125em rgba($scheme-invert, 0.1), 0 0 0 1px $link !default
$box-link-active-shadow: inset 0 1px 2px rgba($scheme-invert, 0.2), 0 0 0 1px $link !default

.box
  @extend %block
  background-color: $box-background-color
  border-radius: $box-radius
  box-shadow: $box-shadow
  color: $box-color
  display: block
  padding: $box-padding

a.box
  &:hover,
  &:focus
    box-shadow: $box-link-hover-shadow
  &:active
    box-shadow: $box-link-active-shadow
