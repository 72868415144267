@import "../utilities/mixins"

$panel-margin: $block-spacing !default
$panel-item-border: 1px solid $border-light !default
$panel-radius: $radius-large !default
$panel-shadow: $shadow !default

$panel-heading-background-color: $border-light !default
$panel-heading-color: $text-strong !default
$panel-heading-line-height: 1.25 !default
$panel-heading-padding: 0.75em 1em !default
$panel-heading-radius: $radius !default
$panel-heading-size: 1.25em !default
$panel-heading-weight: $weight-bold !default

$panel-tabs-font-size: 0.875em !default
$panel-tab-border-bottom: 1px solid $border !default
$panel-tab-active-border-bottom-color: $link-active-border !default
$panel-tab-active-color: $link-active !default

$panel-list-item-color: $text !default
$panel-list-item-hover-color: $link !default

$panel-block-color: $text-strong !default
$panel-block-hover-background-color: $background !default
$panel-block-active-border-left-color: $link !default
$panel-block-active-color: $link-active !default
$panel-block-active-icon-color: $link !default

$panel-icon-color: $text-light !default
$panel-colors: $colors !default

.panel
  border-radius: $panel-radius
  box-shadow: $panel-shadow
  font-size: $size-normal
  &:not(:last-child)
    margin-bottom: $panel-margin
  // Colors
  @each $name, $components in $panel-colors
    $color: nth($components, 1)
    $color-invert: nth($components, 2)
    &.is-#{$name}
      .panel-heading
        background-color: $color
        color: $color-invert
      .panel-tabs a.is-active
        border-bottom-color: $color
      .panel-block.is-active .panel-icon
        color: $color

.panel-tabs,
.panel-block
  &:not(:last-child)
    border-bottom: $panel-item-border

.panel-heading
  background-color: $panel-heading-background-color
  border-radius: $panel-radius $panel-radius 0 0
  color: $panel-heading-color
  font-size: $panel-heading-size
  font-weight: $panel-heading-weight
  line-height: $panel-heading-line-height
  padding: $panel-heading-padding

.panel-tabs
  align-items: flex-end
  display: flex
  font-size: $panel-tabs-font-size
  justify-content: center
  a
    border-bottom: $panel-tab-border-bottom
    margin-bottom: -1px
    padding: 0.5em
    // Modifiers
    &.is-active
      border-bottom-color: $panel-tab-active-border-bottom-color
      color: $panel-tab-active-color

.panel-list
  a
    color: $panel-list-item-color
    &:hover
      color: $panel-list-item-hover-color

.panel-block
  align-items: center
  color: $panel-block-color
  display: flex
  justify-content: flex-start
  padding: 0.5em 0.75em
  input[type="checkbox"]
    +ltr-property("margin", 0.75em)
  & > .control
    flex-grow: 1
    flex-shrink: 1
    width: 100%
  &.is-wrapped
    flex-wrap: wrap
  &.is-active
    border-left-color: $panel-block-active-border-left-color
    color: $panel-block-active-color
    .panel-icon
      color: $panel-block-active-icon-color
  &:last-child
    border-bottom-left-radius: $panel-radius
    border-bottom-right-radius: $panel-radius

a.panel-block,
label.panel-block
  cursor: pointer
  &:hover
    background-color: $panel-block-hover-background-color

.panel-icon
  +fa(14px, 1em)
  color: $panel-icon-color
  +ltr-property("margin", 0.75em)
  .fa
    font-size: inherit
    line-height: inherit
