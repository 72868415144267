@import "../utilities/mixins"

.is-radiusless
  border-radius: 0 !important

.is-shadowless
  box-shadow: none !important

.is-clickable
  cursor: pointer !important
  pointer-events: all !important

.is-unselectable
  @extend %unselectable
